const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id: 'us-east-1:540c3750-680a-436a-96a1-131316eeb716',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_mrjB62hhH',
  aws_user_pools_web_client_id: '4bf19dgir2bargvumdbul7fo10',
  oauth: {},
  API: {
      GraphQL: {
          endpoint: 'https://xitxijdw4vduvcchnyrvkm7urm.appsync-api.us-east-1.amazonaws.com/graphql',
          region: 'us-east-1',
          defaultAuthMode: 'apiKey',
          apiKey: 'da2-7xohj4oyavdyzjqqlbbyorwrxm'
      }
  }
};

export default awsmobile;
