import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../logo.png';
import './PaymentComponent.css';


let globalUsername = '';
let globalUserId = '';
let globalEmail = '';
let globalConcertId = '';
let globalPaymentAmount = '';
let globalPaymentType = 'concert';

const PaymentComponent = () => {
  const [username, setUsername] = useState('');
  const [userId, setUserId] = useState('');
  const [email, setEmail] = useState('');
  const [concertId, setConcertId] = useState(''); // Change concertName to concertId
  const [paymentAmount, setPaymentAmount] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [statusType, setStatusType] = useState('');
  const [paymentType, setPaymentType] = useState('concert');

  const cardInitialized = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    console.log("URL Params:", Array.from(urlParams.entries()));
    setUsername(urlParams.get('username') || '');
    setUserId(urlParams.get('userId') || '');
    setEmail(urlParams.get('email') || '');
    setConcertId(urlParams.get('concertId') || '');
    setPaymentAmount(urlParams.get('paymentAmount') || '');
    setPaymentType(urlParams.get('paymentType') || 'concert');

    globalUsername = urlParams.get('username') || '';
    globalUserId = urlParams.get('userId') || '';
    globalEmail = urlParams.get('email') || '';
    globalConcertId = urlParams.get('concertId') || '';
    globalPaymentAmount = urlParams.get('paymentAmount') || '';
    globalPaymentType = urlParams.get('paymentType') || 'concert';

    const loadSquareSDK = async () => {
      const script = document.createElement('script');
      script.src = 'https://sandbox.web.squarecdn.com/v1/square.js';
      script.async = true;
      script.onload = initializeCardPayment;
      document.body.appendChild(script);
    };

    const initializeCardPayment = async () => {
      if (!cardInitialized.current && window.Square) {
        cardInitialized.current = true;

        const payments = window.Square.payments(
          'sandbox-sq0idb-Va7VqzcPQpgVzT18NFFN4Q',
          'TC4Z3ZEBKRXRH'
        );

        const card = await payments.card();
        await card.attach('#card-container');

        const cardButton = document.getElementById('card-button');
        cardButton.addEventListener('click', async () => {
          try {
            const result = await card.tokenize();
            if (result.status === 'OK') {
              setPaymentStatus('Payment Successful');
              handlePayment(result.token);
            } else {
              console.error(`Tokenization failed with status: ${result.status}`);
              setPaymentStatus('Payment Failed');
              setStatusType('error');
            }
          } catch (error) {
            console.error('Payment Error:', error);
            setPaymentStatus('Payment Failed');
            setStatusType('error');
          }
        });
      }
    };

    loadSquareSDK();
  }, []);

  const handlePayment = async (token) => {
    const paymentData = {
      token,
      username: globalUsername,
      userId: globalUserId,
      email: globalEmail,
      paymentAmount: globalPaymentAmount,
      paymentType: globalPaymentType,
      concertId: globalPaymentType === 'concert' ? globalConcertId : null,
      donationRecipientId: globalPaymentType === 'donation' ? 'donationRecipientId123' : null,
    };

    console.log("Payment Data", paymentData);

    try {
      const response = await fetch('https://b9pdqzzwzk.execute-api.us-east-1.amazonaws.com/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': '*/*', 'Connection': 'keep-alive' },
        body: JSON.stringify(paymentData),
        mode: 'cors'
      });

      const responseData = await response.json();
      if (response.ok) {
        console.log('Lambda triggered successfully:', responseData);
        setPaymentStatus('Payment processed successfully');
        navigate('/thank-you');
      } else {
        console.error('Lambda trigger failed:', responseData);
        setPaymentStatus('Payment processing failed');
        setStatusType('error');
      }
    } catch (error) {
      console.error('Error calling Lambda:', error);
      setPaymentStatus('Error processing payment');
      setStatusType('error');
    }
  };

  // Define the getPaymentDetails function inside the component
  const getPaymentDetails = () => {
    if (paymentType === 'concert') {
      return (
        <>
          <h2>User: {username}</h2>
          <p>Concert ID: {concertId}</p>
          <p>Amount: ${paymentAmount}</p>
        </>
      );
    } else if (paymentType === 'donation') {
      return (
        <>
          <h2>Hello, {username}!</h2>
          <p>Donation Amount: ${paymentAmount}</p>
        </>
      );
    }
  };

  return (
    <div className="login-container">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo-image" />
      </div>

      <div className="payment-details">
        {getPaymentDetails()} {/* Call getPaymentDetails function here */}
      </div>

      <div id="payment-form">
        <div id="payment-status-container" className={statusType}>
          {paymentStatus}
        </div>
        <div id="card-container"></div>
        <button id="card-button" type="button">
          {paymentType === 'concert' ? 'Make Payment' : 'Donate'}
        </button>
      </div>
    </div>
  );
};

export default PaymentComponent;
