import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Login from './components/Login';
import AdminConsole from './components/AdminConsole';
import PrivacyPolicy from './components/PrivacyPolicy';
import DeleteAccount from './components/DeleteAccount';
import PaymentComponent from './components/PaymentComponent';
import ThankYouPage from './components/ThankYouPage';
import Footer from './components/Footer';
import awsConfig from './aws-exports';
import './App.css';

Amplify.configure(awsConfig);

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('isLoggedIn');
  };

  return (
    <Router>
      <div className="App">
        <Navbar isLoggedIn={isLoggedIn} onLogout={handleLogout} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route
            path="/login"
            element={<Login onLogin={handleLogin} />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/admin-console" element={<AdminConsole />} />
          <Route path="/payment" element={<PaymentComponent />} />
          <Route path="/delete-account" element={<DeleteAccount />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
