import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signIn, getCurrentUser } from 'aws-amplify/auth'; // Ensure this import is correct for your version
import logo from '../logo.png';
import './Login.css';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('isLoggedIn');

    // Check if the user is already logged in
    if (isLoggedIn === 'true') {
      console.log('isLoggedIn status is currently true');
      navigate('/admin-console'); // Redirect if already logged in
    } else {
      console.log('isLoggedIn status is currently false');
    }

    // Get current user details
    const fetchCurrentUser = async () => {
      try {
        const user = await getCurrentUser();
        console.log('Current user details:', user);

        // If a valid user is found, mimic logging them in
        if (user && user.username) {
          console.log(`Mimicking login for user: ${user.username}`);
          localStorage.setItem('isLoggedIn', 'true'); // Update login status
          onLogin(); // Call the onLogin callback
          navigate('/admin-console'); // Redirect to the home page
        }
      } catch (error) {
        console.log('No current user or error fetching user:', error);
      }
    };

    fetchCurrentUser(); // Call the function to fetch user details
  }, [navigate, onLogin]);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // Validate username and password before calling signIn
      if (!username || !password) {
        setError('Username and password are required');
        return;
      }

      // Sign in user
      const { isSignUpComplete, userId } = await signIn({ username, password });

      console.log("isSignUpComplete:", isSignUpComplete, "UserId:", userId);

      const adminUsers = ['shmusik', 'shmusik_admin', 'shmusik_dev', 'javyduty'];

      if (isSignUpComplete && adminUsers.includes(username)) {
        console.log('Logged in successfully');
        setError('');
        localStorage.setItem('isLoggedIn', 'true'); // Update login status
        onLogin(); // Call the onLogin callback
        navigate('/admin-console'); // Redirect to the home page
        navigate(0);
      } else {
        setError('User exists but login not allowed');
      }
    } catch (error) {
      console.log("Error is", error);
      // Handle specific error codes
      if (error.code === 'UserNotFoundException') {
        setError('User does not exist');
      } else if (error.code === 'NotAuthorizedException') {
        setError('Incorrect username or password');
      } else if (error.code === 'UserNotConfirmedException') {
        setError('User is not confirmed');
      } else if (error === "UserAlreadyAuthenticatedException") {
        setError('User is already signed in.');
      } else {
        setError('Login error');
      }
    }
  };

  return (
    <div className="login-container">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo-image" />
      </div>
      <h2>Login</h2>
      {error && <p>{error}</p>}
      <form onSubmit={handleLogin}>
        <div>
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            name="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;
