import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from 'aws-amplify/auth'; // Import the signOut function
import './Navbar.css';

const Navbar = ({ onLogout }) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const storedLoginStatus = localStorage.getItem('isLoggedIn');
    setIsLoggedIn(storedLoginStatus === 'true');
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(); // Sign out from AWS Amplify
      localStorage.removeItem('isLoggedIn'); // Remove login status
      onLogout(); // Call the logout function passed as a prop
      setIsLoggedIn(false); // Update local state
      navigate('/'); // Redirect to home page
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleAdminClick = () => {
    navigate('/admin-console');
  };

  return (
    <nav className="navbar">
      <ul className="navbar-list">
        <li className="navbar-item">
          <Link to="/" className="navbar-link">Home</Link>
        </li>
        <li className="navbar-item">
          <Link to="/about" className="navbar-link">About</Link>
        </li>
        <li className="navbar-item">
          <Link to="/contact" className="navbar-link">Contact</Link>
        </li>
        {/* {isLoggedIn && (
          <li className="navbar-item">
            <button className="navbar-link admin-button" onClick={handleAdminClick}>Admin</button>
          </li>
        )} */}
        {/* <li className="navbar-item">
          {isLoggedIn ? (
            <button className="navbar-link logout-button" onClick={handleLogout}>Logout</button>
          ) : (
            <Link to="/login" className="navbar-link">Login</Link>
          )}
        </li> */}
      </ul>
    </nav>
  );
};

export default Navbar;
