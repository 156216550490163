// src/ThankYouPage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ThankYouPage.css';

const ThankYouPage = () => {
  const navigate = useNavigate();

  // Disable going back to the payment page
  useEffect(() => {
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = () => navigate('/', { replace: true }); // Redirect to home if user tries to go back
  }, [navigate]);

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Thank You for Your Payment!</h1>
      <p>Your payment was processed successfully.</p>
    </div>
  );
};

export default ThankYouPage;
