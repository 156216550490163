import React from 'react';

const DeleteAccount = () => {
  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h1>How to Delete Your Account</h1>
      <p>
        To delete your account, please follow these steps:
      </p>
      <ol style={{ textAlign: 'left', margin: '0 auto', maxWidth: '600px' }}>
        <li>Log in to the app with your account credentials.</li>
        <li>Navigate to your profile page by tapping on your avatar or username.</li>
        <li>Once on your profile, press the options button (usually represented by three dots).</li>
        <li>Select the "Delete Account" option from the menu that appears.</li>
        <li>Follow the prompts to confirm that you wish to delete your account.</li>
      </ol>
      <p>
        Please note that once your account is deleted, all your data will be permanently removed, and you won't be able to recover it.
      </p>
    </div>
  );
}

export default DeleteAccount;
